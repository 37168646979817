
import { useEffect, useRef, useCallback, useState } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Zap, ArrowUp, ArrowDown } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';

import './ConsolePage.scss';

const LOCAL_RELAY_SERVER_URL: string = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';
const OPENAI_API_KEY: string = process.env.REACT_APP_OPENAI_API_KEY || '';

console.log('Environment variables:', {
  LOCAL_RELAY_SERVER_URL,
  OPENAI_API_KEY: OPENAI_API_KEY ? 'Set' : 'Not set'
});

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {
  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: OPENAI_API_KEY,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

/**
 * References for
 * - Rendering audio visualization (canvas)
 * - Autoscrolling event logs
 * - Timing delta for event log displays
 */
const clientCanvasRef = useRef<HTMLCanvasElement>(null);
const serverCanvasRef = useRef<HTMLCanvasElement>(null);
const eventsScrollHeightRef = useRef(0);
const eventsScrollRef = useRef<HTMLDivElement>(null);
const startTimeRef = useRef<string>(new Date().toISOString());

/**
 * All of our variables for displaying application state
 * - items are all conversation items (dialog)
 * - realtimeEvents are event logs, which can be expanded
 */
const [items, setItems] = useState<ItemType[]>([]);
const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
const [expandedEvents, setExpandedEvents] = useState<{
  [key: string]: boolean;
}>({});
const [isConnected, setIsConnected] = useState(false);
const [canPushToTalk, setCanPushToTalk] = useState(true);
const [isRecording, setIsRecording] = useState(false);

/**
 * Utility for formatting the timing of logs
 */
const formatTime = useCallback((timestamp: string) => {
  const startTime = startTimeRef.current;
  const t0 = new Date(startTime).valueOf();
  const t1 = new Date(timestamp).valueOf();
  const delta = t1 - t0;
  const hs = Math.floor(delta / 10) % 100;
  const s = Math.floor(delta / 1000) % 60;
  const m = Math.floor(delta / 60_000) % 60;
  const pad = (n: number) => {
    let s = n + '';
    while (s.length < 2) {
      s = '0' + s;
    }
    return s;
  };
  return `${pad(m)}:${pad(s)}.${pad(hs)}`;
}, []);

/**
 * Connect to conversation:
 * WavRecorder taks speech input, WavStreamPlayer output, client is API client
 */
const connectConversation = useCallback(async () => {
  try {
    console.log('Starting connection process...');
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    console.log('Setting up state variables...');
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    console.log('Connecting to microphone...');
    await wavRecorder.begin();

    console.log('Connecting to audio output...');
    await wavStreamPlayer.connect();

    console.log('Connecting to realtime API...');
    await client.connect();
    
    console.log('Setting up VAD if needed...');
    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }

    console.log('Connection process completed successfully.');
  } catch (error) {
    console.error('Connection failed:', error);
    setIsConnected(false);
    alert('Failed to connect: ' + (error instanceof Error ? error.message : String(error)));
  }
}, []);

/**
 * Disconnect and reset conversation state
 */
const disconnectConversation = useCallback(async () => {
  setIsConnected(false);
  setRealtimeEvents([]);
  setItems([]);

  const client = clientRef.current;
  client.disconnect();

  const wavRecorder = wavRecorderRef.current;
  await wavRecorder.end();

  const wavStreamPlayer = wavStreamPlayerRef.current;
  await wavStreamPlayer.interrupt();
}, []);

const deleteConversationItem = useCallback(async (id: string) => {
  const client = clientRef.current;
  client.deleteItem(id);
}, []);

/**
 * In push-to-talk mode, start recording
 * .appendInputAudio() for each sample
 */
const startRecording = async () => {
  setIsRecording(true);
  const client = clientRef.current;
  const wavRecorder = wavRecorderRef.current;
  const wavStreamPlayer = wavStreamPlayerRef.current;
  const trackSampleOffset = await wavStreamPlayer.interrupt();
  if (trackSampleOffset?.trackId) {
    const { trackId, offset } = trackSampleOffset;
    await client.cancelResponse(trackId, offset);
  }
  await wavRecorder.record((data) => client.appendInputAudio(data.mono));
};

/**
 * In push-to-talk mode, stop recording
 */
const stopRecording = async () => {
  setIsRecording(false);
  const client = clientRef.current;
  const wavRecorder = wavRecorderRef.current;
  await wavRecorder.pause();
  client.createResponse();
};

/**
 * Switch between Manual <> VAD mode for communication
 */
const changeTurnEndType = async (value: string) => {
  const client = clientRef.current;
  const wavRecorder = wavRecorderRef.current;
  if (value === 'none' && wavRecorder.getStatus() === 'recording') {
    await wavRecorder.pause();
  }
  client.updateSession({
    turn_detection: value === 'none' ? null : { type: 'server_vad' },
  });
  if (value === 'server_vad' && client.isConnected()) {
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  }
  setCanPushToTalk(value === 'none');
};

/**
 * Auto-scroll the event logs
 */
useEffect(() => {
  if (eventsScrollRef.current) {
    const eventsEl = eventsScrollRef.current;
    const scrollHeight = eventsEl.scrollHeight;
    // Only scroll if height has just changed
    if (scrollHeight !== eventsScrollHeightRef.current) {
      eventsEl.scrollTop = scrollHeight;
      eventsScrollHeightRef.current = scrollHeight;
    }
  }
}, [realtimeEvents]);

/**
 * Auto-scroll the conversation logs
 */
useEffect(() => {
  const conversationEls = [].slice.call(
    document.body.querySelectorAll('[data-conversation-content]')
  );
  for (const el of conversationEls) {
    const conversationEl = el as HTMLDivElement;
    conversationEl.scrollTop = conversationEl.scrollHeight;
  }
}, [items]);

/**
 * Set up render loops for the visualization canvas
 */
useEffect(() => {
  let isLoaded = true;

  const wavRecorder = wavRecorderRef.current;
  const clientCanvas = clientCanvasRef.current;
  let clientCtx: CanvasRenderingContext2D | null = null;

  const wavStreamPlayer = wavStreamPlayerRef.current;
  const serverCanvas = serverCanvasRef.current;
  let serverCtx: CanvasRenderingContext2D | null = null;

  const render = () => {
    if (isLoaded) {
      if (clientCanvas) {
        if (!clientCanvas.width || !clientCanvas.height) {
          clientCanvas.width = clientCanvas.offsetWidth;
          clientCanvas.height = clientCanvas.offsetHeight;
        }
        clientCtx = clientCtx || clientCanvas.getContext('2d');
        if (clientCtx) {
          clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
          const result = wavRecorder.recording
            ? wavRecorder.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(
            clientCanvas,
            clientCtx,
            result.values,
            '#0099ff',
            10,
            0,
            8
          );
        }
      }
      if (serverCanvas) {
        if (!serverCanvas.width || !serverCanvas.height) {
          serverCanvas.width = serverCanvas.offsetWidth;
          serverCanvas.height = serverCanvas.offsetHeight;
        }
        serverCtx = serverCtx || serverCanvas.getContext('2d');
        if (serverCtx) {
          serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
          const result = wavStreamPlayer.analyser
            ? wavStreamPlayer.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(
            serverCanvas,
            serverCtx,
            result.values,
            '#009900',
            10,
            0,
            8
          );
        }
      }
      window.requestAnimationFrame(render);
    }
  };
  render();

  return () => {
    isLoaded = false;
  };
}, []);

/**
 * Core RealtimeClient and audio capture setup
 */
useEffect(() => {
  // Get refs
  const wavStreamPlayer = wavStreamPlayerRef.current;
  const client = clientRef.current;

  // Set instructions
  client.updateSession({ 
    instructions: instructions,
    input_audio_transcription: { model: 'whisper-1' },
    voice: 'alloy',  // Available voices in current API: alloy, shimmer, echo
    temperature: 0.6  // Lower temperature for more focused translations
  });

  // handle realtime events from client + server for event logging
  client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
    setRealtimeEvents((realtimeEvents) => {
      const lastEvent = realtimeEvents[realtimeEvents.length - 1];
      if (lastEvent?.event.type === realtimeEvent.event.type) {
        // if we receive multiple events in a row, aggregate them for display purposes
        lastEvent.count = (lastEvent.count || 0) + 1;
        return realtimeEvents.slice(0, -1).concat(lastEvent);
      } else {
        return realtimeEvents.concat(realtimeEvent);
      }
    });
  });
  client.on('error', (event: any) => console.error(event));
  client.on('conversation.interrupted', async () => {
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
  });
  client.on('conversation.updated', async ({ item, delta }: any) => {
    const items = client.conversation.getItems();
    if (delta?.audio) {
      wavStreamPlayer.add16BitPCM(delta.audio, item.id);
    }
    if (item.status === 'completed' && item.formatted.audio?.length) {
      const wavFile = await WavRecorder.decode(
        item.formatted.audio,
        24000,
        24000
      );
      item.formatted.file = wavFile;
    }
    setItems(items);
  });

  setItems(client.conversation.getItems());

  return () => {
    // cleanup; resets to defaults
    client.reset();
  };
}, []);

/**
 * Render the application
 */
return (
  <div data-component="ConsolePage">
    <div className="content-top">
      <div className="content-title">
        <img src="/logoHubFelix.svg" />
        <span>Assistente de Voz HubFelix</span>
      </div>
    </div>
    <div className="content-main">
      <div className="content-logs">
        <div className="content-block events">
          <div className="visualization">
            <div className="visualization-entry client">
              <canvas ref={clientCanvasRef} />
            </div>
            <div className="visualization-entry server">
              <canvas ref={serverCanvasRef} />
            </div>
          </div>
          <div className="content-block-title">Eventos</div>
          <div className="content-block-body" ref={eventsScrollRef}>
            {!realtimeEvents.length && `Aguardando início...`}
            {realtimeEvents.map((realtimeEvent, i) => {
              const count = realtimeEvent.count;
              const event = { ...realtimeEvent.event };
              if (event.type === 'input_audio_buffer.append') {
                event.audio = `[trimmed: ${event.audio.length} bytes]`;
              } else if (event.type === 'response.audio.delta') {
                event.delta = `[trimmed: ${event.delta.length} bytes]`;
              }
              return (
                <div className="event" key={event.event_id}>
                  <div className="event-timestamp">
                    {formatTime(realtimeEvent.time)}
                  </div>
                  <div className="event-details">
                    <div
                      className="event-summary"
                      onClick={() => {
                        // toggle event details
                        const id = event.event_id;
                        const expanded = { ...expandedEvents };
                        if (expanded[id]) {
                          delete expanded[id];
                        } else {
                          expanded[id] = true;
                        }
                        setExpandedEvents(expanded);
                      }}
                    >
                      <div
                        className={`event-source ${
                          event.type === 'error'
                            ? 'error'
                            : realtimeEvent.source
                        }`}
                      >
                        {realtimeEvent.source === 'client' ? (
                          <ArrowUp />
                        ) : (
                          <ArrowDown />
                        )}
                        <span>
                          {event.type === 'error'
                            ? 'error!'
                            : realtimeEvent.source}
                        </span>
                      </div>
                      <div className="event-type">
                        {event.type}
                        {count && ` (${count})`}
                      </div>
                    </div>
                    {!!expandedEvents[event.event_id] && (
                      <div className="event-payload">
                        {JSON.stringify(event, null, 2)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="content-block conversation">
          <div className="content-block-title">Conversa</div>
          <div className="content-block-body" data-conversation-content>
            {!items.length && `Aguardando início...`}
            {items.map((conversationItem, i) => {
              return (
                <div className="conversation-item" key={conversationItem.id}>
                  <div className={`speaker ${conversationItem.role || ''}`}>
                    <div>
                      {(
                        conversationItem.role || conversationItem.type
                      ).replaceAll('_', ' ')}
                    </div>
                    <div
                      className="close"
                      onClick={() =>
                        deleteConversationItem(conversationItem.id)
                      }
                    >
                      <X />
                    </div>
                  </div>
                  <div className={`speaker-content`}>
                    {/* tool response */}
                    {conversationItem.type === 'function_call_output' && (
                      <div>{conversationItem.formatted.output}</div>
                    )}
                    {/* tool call */}
                    {!!conversationItem.formatted.tool && (
                      <div>
                        {conversationItem.formatted.tool.name}(
                        {conversationItem.formatted.tool.arguments})
                      </div>
                    )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === 'user' && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            (conversationItem.formatted.audio?.length
                              ? '(awaiting transcript)'
                              : conversationItem.formatted.text ||
                                '(item sent)')}
                        </div>
                      )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === 'assistant' && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            conversationItem.formatted.text ||
                            '(truncated)'}
                        </div>
                      )}
                    {conversationItem.formatted.file && (
                      <audio
                        src={conversationItem.formatted.file.url}
                        controls
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="content-actions">
          <Toggle
            defaultValue={false}
            labels={['Manual', 'Auto']}
            values={['none', 'server_vad']}
            onChange={(_, value) => changeTurnEndType(value)}
          />
          <div className="spacer" />
          {isConnected && canPushToTalk && (
            <Button
              label={isRecording ? 'Release' : 'Press'}
              buttonStyle={isRecording ? 'alert' : 'regular'}
              disabled={!isConnected || !canPushToTalk}
              onMouseDown={startRecording}
              onMouseUp={stopRecording}
            />
          )}
          <div className="spacer" />
          <Button
            label={isConnected ? 'Stop' : 'Start'}
            iconPosition={isConnected ? 'end' : 'start'}
            icon={isConnected ? X : Zap}
            buttonStyle={isConnected ? 'regular' : 'action'}
            onClick={
              isConnected ? disconnectConversation : connectConversation
            }
          />
        </div>
      </div>
    </div>
  </div>
);
}
